import $ from 'jquery';
import EventEmitter from 'events';
import prestashop from 'prestashop';

import './core/cart';
import './core/checkout';
import './core/facets';
import './core/listing';
import './core/product';
import './core/address';

import { psShowHide } from './core/common';

/* expose jQuery for modules */
window.$ = $;
window.jQuery = $;

// "inherit" EventEmitter
// eslint-disable-next-line no-restricted-syntax, guard-for-in
for (const i in EventEmitter.prototype) {
  prestashop[i] = EventEmitter.prototype[i];
}

$(document).ready(() => {
  psShowHide();
});


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
