import $ from 'jquery';
import prestashop from 'prestashop';

/**
 * Update address form on country change
 * Emit "addressFormUpdated" event
 *
 * @param selectors
 */
function handleCountryChange(selectors) {
  $('body').on('change', selectors.country, () => {
    const requestData = {
      /* eslint-disable camelcase */
      id_country: $(selectors.country).val(),
      id_address: $(`${selectors.address} form`).data('id-address'),
      /* eslint-enable camelcase */
    };
    const getFormViewUrl = $(`${selectors.address} form`).data('refresh-url');
    const formFieldsSelector = `${selectors.address} input`;

    $.post(getFormViewUrl, requestData)
      .then(function postCallback(resp) {
        const inputs = [];

        // Store fields values before updating form
        $(formFieldsSelector).each(function each() {
          inputs[$(this).prop('name')] = $(this).val();
        });

        $(selectors.address).replaceWith(resp.address_form);

        // Restore fields values
        $(formFieldsSelector).each(function each() {
          $(this).val(inputs[$(this).prop('name')]);
        });

        prestashop.emit('updatedAddressForm', { target: $(selectors.address), resp });
      })
      .fail((resp) => {
        prestashop.emit('handleError', { eventType: 'updateAddressForm', resp });
      });
  });
}

$(document).ready(() => {
  handleCountryChange({
    country: '.js-country',
    address: '.js-address-form',
  });
});


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
